<template>
  <div class="blogbook-assistant">
    <b-card v-if="showTip">
      <b-card-title class="title">
        <fa icon="weixin" class="text-success"/>
        微信公众号导出/更新
      </b-card-title>
      <div class="tip-box">
        <div>导出/更新微信公众号内容需要登录小助手，具体流程如下：</div>
        <div>1. 手机微信扫码登录小助手，如显示小助手在忙请稍后刷新页面重试</div>
        <div>2. 等待小助手加载完成，选择自己要导出/更新的公众号名称，点击导出即可</div>
        <div class="text-danger">注意：每人每天仅可导出/更新3个公众号</div>
      </div>
      <b-btn style="float: left;" to="/create">
        <fa icon="chevron-left"></fa>
        返回
      </b-btn>
      <b-btn style="float: right;" variant="primary" @click="into">
        我知道了，开始操作
      </b-btn>
    </b-card>
    <b-card v-else>
      <b-card-title class="title">
        <fa icon="weixin" class="text-success"/>
        公众号小助手
      </b-card-title>
      <loading v-if="loadingTimes" text="正在检测今日次数"/>
      <template v-else>
        <template v-if="showLogin">
          <b class="text-danger">请重新登录微信书</b>
        </template>
        <template v-else>
          <template v-if="restCount === 0">
            <div class="notimes"><b class="text-danger">今日导出次数已用完，请明天再来</b></div>
            <b-btn style="float: left;" @click="back">
              <fa icon="chevron-left"></fa>
              返回
            </b-btn>
          </template>
          <template v-else>
            <b class="text-danger">今日剩余导出次数：{{restCount}}</b>
            <template v-if="showQrcode">
              <loading v-if="loadingAst" text="小助手准备中"/>
              <template v-else>
                <template v-if="!worker.id">
                  <div class="noworker">暂无可用小助手，请稍后再来</div>
                  <b-btn style="float: left;" @click="back">
                    <fa icon="chevron-left"></fa>
                    返回
                  </b-btn>
                </template>
                <template v-else>
                  <div class="text-user" v-if="user">
                    限本账号
                    (<img :src="user.avatar" class="avatar-sm" alt=""> {{user.nickname}})
                    绑定的微信
                  </div>
                  <b class="text-danger">
                    需摄像头扫码，不支持长按识别或相册识别
                    <br>
                  </b>
                  <div class="qrcode-box">
                    <qrcode :src="qrcode" raw size="180px">
                      <div class="mask" v-if="qrcodeExpired">
                        <b-btn @click="refreshAssistant" variant="primary">
                          <fa icon="sync"/>
                          刷新
                        </b-btn>
                      </div>
                    </qrcode>
                  </div>
                  <div v-if="qrcodeExpired">二维码已失效<br>点击上方按钮刷新</div>
                  <div v-else>
                    <div>
                      扫码后请点击登录按钮
                      <br>如长时间（1分钟以上）无反应
                      <br>请尝试再次<b>扫码登录</b>
                    </div>
                  </div>
                  <b-btn style="float: left;" @click="back">
                    <fa icon="chevron-left"></fa>
                    返回
                  </b-btn>
                </template>
              </template>
            </template>
            <template v-else>
              <loading v-if="wxmps.length === 0" text="正在抓取公众号，请稍等"/>
              <template v-else>
                <div class="search-input">
                  <b-input placeholder="搜索公众号" autofocus v-model.trim="message"/>
                </div>
                <div class="list">
                  <div class="list-item"
                       v-for="(item) in filterWxmps"
                       :key="item.sourceId"
                       @click="toggleSelect(item)">
                    <span>
                      <fa icon="check-circle"
                          :class="{'text-primary': selected.indexOf(item) > -1}"
                          v-if="selected.indexOf(item) > -1"/>
                      <fa icon="circle" far class="text-muted" v-else/>
                    </span>
                    &nbsp;
                    <img :src="item.imgUrl" class="avatar-sm" alt="">
                    <span class="item-title">
                      {{item.sourceName}}
                    </span>
                  </div>
                </div>
                <empty v-if="!filterWxmps.length">没有相关公众号</empty>
              </template>
              <b-btn :disabled="!job.canExitAssistant" style="float: left;" @click="exitAndBack">
                <fa icon="sign-out"></fa>
                退出小助手
              </b-btn>
              <b-btn v-if="wxmps.length > 0"
                     :disabled="selected.length === 0"
                     variant="primary"
                     style="float: right;"
                     @click="start">
                导出已选 ({{selected.length}})
              </b-btn>
            </template>
          </template>
        </template>
      </template>
    </b-card>
  </div>
</template>

<script>
import Qrcode from '@/components/QRCode'

export default {
  name: 'blogbookAssistant',
  title: '微信公众号小助手',
  components: {
    Qrcode
  },
  data() {
    return {
      showTip: true,
      worker: {},
      job: {},
      showLogin: false,
      qrcodeExpired: false,
      stopCheck: false,
      interval: null,
      stopInterval: false,
      mpinterval: null,
      restCount: 3,
      wxmps: [],
      selected: [],
      loadingTimes: false,
      loadingAst: false,
      showQrcode: false,
      qrcode: '',
      message: '',
      filterWxmps: []
    }
  },
  beforeDestroy() {
    this.stopCheck = true
    this.stopInterval = true
  },
  async beforeRouteLeave(to, from, next) {
    if (this.worker?.id) {
      await this.freeAssistant(this.worker.id)
    }
    if (this.worker?.jobId) {
      await this.exit(this.worker.jobId)
    }
    next()
  },
  watch: {
    message: {
      handler(newval, oldval) {
        if (newval === '') {
          this.filterWxmps = this.wxmps
        } else {
          this.filterWxmps = this.selectMatchItem(this.wxmps, newval)
        }
      },
      immediate: true
    },
    worker: {
      handler(worker) {
        if (worker) {
          if (/^http/.test(worker.qrcode)) {
            this.qrcode = 'https://canvas.xinshu.me/qrcode?text=' +
              encodeURIComponent(worker.qrcode)
          }
        }
      },
      deep: true
    },
    showQrcode: {
      async handler(newVal) {
        if (newVal === true) {
          await this.getWeixinmpCount()
          if (this.restCount > 0) {
            this.getAssistant()
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    selectMatchItem(items, keyWord) {
      const reg = new RegExp(keyWord, 'i')
      const resArr = []
      for (const item of items) {
        if (reg.test(item.sourceName)) {
          resArr.push(item)
        }
      }
      return resArr
    },
    into() {
      this.showTip = false
      this.showQrcode = true
      if (!this.hasLogin) {
        this.showLogin = true
      }
    },
    toggleSelect(item) {
      if (this.selected.indexOf(item) === -1) {
        if (this.selected.length >= this.restCount) {
          this.selected.shift()
        }
        this.selected = this.selected.concat(item)
      } else {
        this.selected = this.selected.filter(i => i !== item)
      }
    },
    async getWeixinmpCount() {
      this.loadingTimes = true
      try {
        const {rest} = await this.$req.get('/blogbook/crawler/weixinmp_count/')
        this.restCount = rest
      } catch (err) {
        this.$alert.error('网络异常，请刷新重试')
      } finally {
        this.loadingTimes = false
      }
    },
    async getAssistant() {
      try {
        this.loadingAst = true
        const results = await this.$ajax.fetchAssistant()
        if (results.length > 0) {
          this.worker = results[0]
          this.checkLoginAst()
        }
      } finally {
        this.loadingAst = false
      }
    },
    refreshAssistant() {
      this.getAssistant().then(() => {
        this.qrcodeExpired = false
      })
    },
    checkWxmps() {
      let checkTimes = 0
      const maxTimes = 148
      clearInterval(this.mpinterval)
      this.mpinterval = setInterval(() => {
        if (this.stopInterval) {
          clearInterval(this.mpinterval)
          return
        }
        if (checkTimes > maxTimes) {
          clearInterval(this.mpinterval)
          this.exit(this.worker.jobId)
          this.showQrcode = true
          return
        }
        checkTimes++
        return this.$req.get(`/blogbook/crawler/assistant_jobs/${this.worker.jobId}/`).then(res => {
          this.job = res
          if (res.status === 'started') {
            if (res.wxMps.length > 0) {
              clearInterval(this.mpinterval)
              this.wxmps = res.wxMps
              this.filterWxmps = this.wxmps
            }
          } else if (res.status === 'timeout_exit') {
            clearInterval(this.mpinterval)
            this.showQrcode = true
            this.$alert.warn('占用小编超时，强制退出')
          } else if (res.status === 'user_exit') {
            clearInterval(this.mpinterval)
            this.showQrcode = true
            this.$alert.warn('您取消了登录')
          }
        })
      }, 2000)
    },
    checkLoginAst() {
      let checkTimes = 0
      const maxTimes = 58
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.stopCheck) {
          clearInterval(this.interval)
          return
        }
        // 超时，二维码过期
        if (checkTimes > maxTimes) {
          clearInterval(this.interval)
          this.qrcodeExpired = true
          this.worker = {}
          this.freeAssistant(this.worker.id)
          return
        }
        checkTimes++
        return this.$ajax.fetchAssistantDetail({id: this.worker.id}).then(res => {
          this.worker = res
          if (res.jobId) {
            clearInterval(this.interval)
            this.showQrcode = false
            this.checkWxmps()
          }
        })
      }, 2000)
    },
    freeAssistant(id) {
      return this.$req.post(`/blogbook/crawler/assistants/${id}/`, {status: 'free'})
    },
    exit(id) {
      return this.$req.post(`/blogbook/crawler/assistant_jobs/${id}/`, {status: 'web_exit'})
    },
    exitAndBack() {
      this.exit(this.worker.jobId)
      this.$router.push('/books')
    },
    back() {
      this.$router.go(-1)
    },
    async start() {
      const confirmed = await this.$dialog.confirm({
        title: '公众号导出',
        content: '所选的公众号文章即将开始导出，是否继续'
      })
      if (!confirmed) {
        return
      }
      const promises = []
      for (const item of this.selected) {
        const data = {
          sourceSite: 'weixinmp',
          sourceId: item.sourceId,
          sourceName: item.sourceName,
          assistantJobId: this.worker.jobId
        }
        const promise = this.$req.post('/blogbook/crawler/crawl/', data)
        promises.push(promise)
      }
      await Promise.all(promises)
      this.$dialog.confirm({
        title: '公众号导出',
        content: '正在导出，请稍后返回书架查看',
        okTitle: '好的',
        okOnly: true
      })
      this.selected = []
      this.getWeixinmpCount()
    }
  }
}
</script>

<style lang="scss">
body[data-page="wxgzhAssistant"] {
  main .wrapper {
    margin: 0;
    background-color: #fb5c88;
    background-image: url(https://img.xinshu.me/upload/970c7b830c00455d831f3ff9c8262418), linear-gradient(to bottom left, #FE92BC, #FE7479);
    background-size: contain;
    background-position: center 80%;
    background-repeat: no-repeat;
  }
}
</style>
<style lang="scss" scoped>

.blogbook-assistant {
  max-width: 640px;
  text-align: center;
  padding: 3rem 1rem 0;

  .search-input {
    margin: 1rem 2rem 0 2rem;
  }

  .tip-box {
    padding: 3em 3em 5em 3em;
    text-align: left;
    line-height: 3;
  }

  .title {
    font-size: 20px;
    text-align: center;
    color: #333;
    padding: 1em 0;
  }

  .avatar-sm {
    vertical-align: middle;
    width: 1.5em;
    height: 1.5em;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-bottom: 2px;
  }

  .notimes {
    padding-bottom: 3em;
  }

  .noworker {
    padding: 3em 0;
  }

  .qrcode-box {
    padding: 3em 0;
    user-select: none;
  }

  .list {
    text-align: left;
    margin: 1rem;

    @include media-breakpoint-up(sm) {
      columns: 2;
    }

    .list-item {
      cursor: pointer;
      padding: 0.5rem $card-spacer-x;
    }
  }
}
</style>
