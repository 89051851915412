<template>
  <div class="video-player" @click="playPause" @keyup.space="playPause"
       :class="{playing, loaded, loading}">
    <div class="inner">
      <div class="buffer-icon">
        <fa icon="spinner" spin class="icon"/>
      </div>

      <div class="play-icon">
        <fa icon="play" fas class="icon"/>
      </div>

      <square mode="contain"
              :src="$img(poster)"
              class="poster"
              style="background-color: transparent;"
              v-if="poster && !played"/>

      <video style="display: block;"
             width="100%"
             height="100%"
             preload="auto"
             :autoplay="autoplay"
             playsinline
             webkit-playsinline
             x5-video-orientation="portrait"
             x5-video-player-fullscreen="false"
             x5-video-player-type="h5"
             @canplay="onLoaded"
             @loadedmetadata="onLoaded"
             @timeupdate="onProgress"
             @loadstart="loading = true"
             @waiting="loading = true"
             @play="onPlay"
             @pause="onPause"
             @ended="onEnded"
             ref="video"/>

      <div class="audio" @click.stop="toggleMuted">
        <fa icon="volume-slash" fas v-if="muted"/>
        <fa icon="volume-up" fas v-else/>
      </div>

      <div class="progress">
        <div class="progress-bar" :style="{width: progress * 100 + '%'}"></div>
      </div>
    </div>
    <div class="placeholder" :style="{paddingBottom: height}"></div>
  </div>
</template>
<script>
export default {
  name: 'videoPlayer',
  data() {
    return {
      playing: false,
      loaded: false,
      muted: false,
      played: false,
      loading: false,
      progress: 0
    }
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    autoplay: Boolean,
    control: {
      type: Boolean,
      default: false
    },
    poster: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '56.25%'
    }
  },
  mounted() {
    const video = this.$refs.video
    if (this.control) {
      video.controls = 'controls'
    }
    if (this.autoplay) {
      this.muted = true
      video.muted = true
      if (this.isWechat && this.isIOS) {
        document.addEventListener('WeixinJSBridgeReady', () => {
          video.play()
        })
      }
    }
    if (this.src) {
      if (this.poster) {
        video.poster = this.poster
      }
    }
  },
  methods: {
    playPause() {
      const video = this.$refs.video
      if (!video.src) {
        this.loading = true
        video.src = this.src
      }
      if (video.paused) {
        video.play()
        this.playing = true
      } else {
        video.pause()
        this.playing = false
      }
    },
    toggleMuted() {
      this.$refs.video.muted = !this.$refs.video.muted
      this.muted = this.$refs.video.muted
    },
    onPlay() {
      this.playing = true
      this.played = true
      this.loading = false
      this.$emit('play')
    },
    onPause() {
      this.playing = false
      this.$emit('pause')
    },
    onLoaded() {
      if (!this.$refs.video.src) {
        return
      }
      this.loaded = true
      this.loading = false
      this.$emit('loaded')
      console.log('video loaded')
    },
    onProgress(e) {
      const {target} = e
      this.progress = target.currentTime / target.duration
    },
    onEnded() {
      this.playing = false
      this.played = false
      this.progress = 0
      this.$emit('ended')
    }
  }
}
</script>
<style lang="scss" scoped>
.video-player {
  background: #222;
  position: relative;
  border-radius: $border-radius;

  .play-icon, .buffer-icon {
    position: absolute;
    color: #fff;
    width: 3em;
    height: 3em;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 3;
    transition: .3s;
    opacity: 1;
    z-index: 10;
    margin: auto;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, .5);
    text-align: center;
    cursor: pointer;

    .icon {
      display: block;
      position: relative;
    }
  }

  .buffer-icon {
    opacity: 0;
  }

  &.loading {
    .buffer-icon {
      opacity: 1;
    }

    .play-icon {
      opacity: 0;
    }
  }

  &.playing {
    .play-icon {
      opacity: 0;
      pointer-events: none;
    }
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .progress-bar {
      height: 2px;
      background-color: $primary;
    }
  }

  .audio {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 100px;
    z-index: 2;
    color: #fff;
    line-height: 2rem;
    text-align: center;
    width: 2rem;
    height: 2rem;
    background-color: rgba(0, 0, 0, .5);
    cursor: pointer;
  }

  .poster {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 5;
    object-fit: contain;
  }
}
</style>
