<template>
  <div class="gzh-assistant">
    <b-card>
      <b-card-title class="title">
        <fa icon="weixin" class="text-success"/>
        公众号小助手
      </b-card-title>
      <loading v-if="loadingTimes" text="小助手准备中..."/>
      <div v-else>
        <div class="rest-times">
          <b class="text-danger">今日剩余导出次数：{{restCount}}</b>
        </div>
        <div v-if="restCount > 0">
          <b-row align-v="center">
            <b-col>
              <b-input :disabled="searching" placeholder="搜索公众号" v-model.trim="keyword" trim autofocus @keyup.enter="handleSearch"/>
            </b-col>
            <b-col cols="auto" class="pl-0">
              <b-btn :disabled="searching || keyword === ''" variant="primary" @click="handleSearch">搜索</b-btn>
            </b-col>
          </b-row>
          <loading v-if="searching" text="玩命搜索中..."/>
          <div v-else>
            <div class="list">
              <div class="list-item"
                    v-for="(item) in showList"
                    :key="item.userName"
                    @click="toggleSelect(item)">
                <span>
                  <fa icon="check-circle"
                      :class="{'text-primary': selected.indexOf(item) > -1}"
                      v-if="selected.indexOf(item) > -1"/>
                  <fa icon="circle" far class="text-muted" v-else/>
                </span>
                <div class="avatar-box">
                  <avatar :src="item.headHDImgUrl" size="40px" round
                              fallback="https://img.xinshu.me/resource/caa539eadcc24af0b29249346d1c3e04"/>
                </div>
                <div class="item-info">
                  <div class="name">名称：{{item.nickName}}</div>
                  <div class="intro">简介：{{item.signature}}</div>
                </div>
              </div>
            </div>
            <div class="list-foot" v-if="stopSearch">
              <div class="total">共<span class="text-primary"> {{gzhList.length}} </span>个结果</div>
              <div>
                <b-btn :disabled="catalog.currentPage <= 1" @click="catalog.currentPage--">
                  上一页
                </b-btn>
                <span>{{catalog.currentPage}} / {{catalog.totalPage}}</span>
                <b-btn :disabled="catalog.currentPage >= catalog.totalPage" @click="catalog.currentPage++">
                  下一页
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-card v-show="selected.length > 0">
      <div class="select-text">您已选择：</div>
      <div class="list">
        <div class="list-item select-item" v-for="item in selected" :key="item.userName">
          <span @click="deleteSelect(item)"><fa icon="ban" far class="text-muted"></fa></span>
          <div class="avatar-box">
              <avatar :src="item.headHDImgUrl" size="40px" round
                          fallback="https://static.weixinshu.com/assets/images/deer/wink.png"/>
            </div>
            <div class="item-info">
              <div class="name">名称：{{item.nickName}}</div>
            </div>
        </div>
      </div>
      <b-btn class="start-btn"
              :disabled="selected.length === 0"
              variant="primary"
              @click="start">
        开始导出
      </b-btn>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'wxmpAssistant',
  title: '微信公众号小助手',
  components: {
  },
  data() {
    return {
      loadingTimes: false,
      restCount: 0,
      keyword: '',
      searching: false,
      searchInterval: null,
      stopSearch: false,
      searchExpried: false,
      gzhList: [],
      searchStatus: '',
      selected: [],
      catalog: {
        currentPage: 1,
        pageSize: 6,
        totalPage: 0
      }
    }
  },
  beforeDestroy() {
    this.stopSearch = true
  },
  computed: {
    showList() {
      const {currentPage, pageSize} = this.catalog
      const startAt = (currentPage - 1) * pageSize
      return this.gzhList?.slice?.(startAt, startAt + pageSize)
    }
  },
  mounted() {
    this.getWeixinmpCount()
  },
  methods: {
    async getWeixinmpCount() {
      this.loadingTimes = true
      try {
        const {rest} = await this.$req.get('/blogbook/crawler/weixinmp_count/')
        this.restCount = rest
      } catch (err) {
        this.$alert.error('网络异常，请刷新重试')
      } finally {
        this.loadingTimes = false
      }
    },
    handleSearch() {
      this.gzhList = []
      this.catalog.currentPage = 1
      this.catalog.totalPage = 0
      this.searching = true
      this.stopSearch = false
      this.searchExpried = false
      this.fetchGzhData()
    },
    fetchGzhData() {
      let checkTimes = 0
      const maxTimes = 28
      clearInterval(this.searchInterval)
      this.searchInterval = setInterval(() => {
        if (this.stopSearch) {
          clearInterval(this.searchInterval)
          this.gzhList = []
          this.searching = false
          return
        }
        // 超时
        if (checkTimes > maxTimes) {
          this.stopSearch = true
          this.searchExpried = true
          return
        }
        checkTimes++
        return this.$req.get(`/blogbook/crawler/weixinmp_search/?name=${this.keyword}`).then(res => {
          if (res.status === 'finished') {
            clearInterval(this.searchInterval)
            this.stopSearch = true
            this.gzhList = res.mps
            this.catalog.totalPage = Math.ceil(this.gzhList.length / this.catalog.pageSize)
            this.searching = false
          }
        })
      }, 2000)
    },
    toggleSelect(item) {
      if (this.selected.indexOf(item) === -1) {
        if (this.selected.length >= this.restCount) {
          this.selected.shift()
        }
        this.selected = this.selected.concat(item)
      } else {
        this.selected = this.selected.filter(i => i !== item)
      }
    },
    deleteSelect(item) {
      this.selected = this.selected.filter(i => i !== item)
    },
    async start() {
      const promises = []
      for (const item of this.selected) {
        const data = {
          sourceSite: 'weixinmp',
          sourceId: item.userName,
          sourceName: item.nickName,
          weixinmpId: item.userName
        }
        const promise = this.$req.post('/blogbook/crawler/crawl/', data)
        promises.push(promise)
      }
      await Promise.all(promises)
      this.$dialog.confirm({
        title: '公众号导出',
        content: '小编正在努力为你导出，请稍后返回书架查看',
        okTitle: '好的',
        okOnly: true
      })
      this.selected = []
      this.getWeixinmpCount()
    }
  }
}
</script>

<style lang="scss">
body[data-page="wxmpAssistant"] {
  main .wrapper {
    margin: 0;
    background-color: #fb5c88;
    background-image: url(https://img.xinshu.me/upload/970c7b830c00455d831f3ff9c8262418), linear-gradient(to bottom left, #FE92BC, #FE7479);
    background-size: contain;
    background-position: center 80%;
    background-repeat: no-repeat;
  }
}
</style>
<style lang="scss" scoped>
.gzh-assistant {
  max-width: 640px;
  text-align: center;
  padding: 3rem 1rem 0;

  .rest-times {
    margin-bottom: 1rem;
  }

  .start-btn {
    margin-top: 1rem;
    float: right;
  }

  .select-text {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }

  .list-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list {
    margin: 1rem 0;

    .list-item {
      position: relative;
      height: 50px;
      display: flex;
      align-items: center;
      margin: 2rem 0;
      cursor: pointer;

      .avatar-box {
        width: 40px;
        height: 40px;
        margin-left: 1rem;
      }

      .item-info {
        margin-left: 1rem;
        text-align: left;

        .intro {
          font-size: 12px;
          color: #999;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .list-item::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1rem;
      margin: 0 auto;
      width: 95%;
      height: 1px;
      background-color: rgba(0,0,0,.1);;
    }

    .select-item {
      margin: 1rem 0;
    }

    .select-item::after {
      bottom: -0.5rem;
    }
  }
}
</style>
