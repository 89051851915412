<template>
  <div class="bbs-assistant">
    <b-modal id="bbs-protocol" ok-only ok-title="同意" title="用户授权协议">
      <div class="tip-text">
        <div>
          《用户授权协议》（以下简称“本协议”）是心书网络（以下简称“心书”）与用户（以下简称“您”）所订立的有效合约。在接受本协议前，您应仔细阅读本协议项下全部内容。本协议的效力为在本协议项下为您提供的一切服务。
        </div>
        <div style="text-indent: 2em;">1.
          您确认，您同意接受本协议时，您具有完全民事权利能力和民事行为能力，能够独立承担民事责任；本协议内容不受您所属国家或地区法律排斥。如您不具备前述条件或无法同意本协议的任意内容，请勿进行后续操作，并立即停止授权。
        </div>
        <div style="text-indent: 2em;">2.
          您确认，您接受本协议并确认授权后，即视为您同意并授权将您的宝宝树账户的相关信息及数据传递给心书登录并读取。心书会依法使用您所授权的信息，并应对您的信息保密。
        </div>
        <div style="text-indent: 2em;">3.
          您同意，心书有权根据业务发展需要对本协议的内容予以变更，并在心书官方渠道公告，无需另行单独通知您。变更后的协议内容一经公布，即代替原来的协议内容，您须定期审阅本协议及变更后的内容。
        </div>
        <div style="text-indent: 2em;">4. 本协议未尽事宜，参照心书相关协议执行；相关协议与本协议有冲突的，以本协议为准。</div>
        <div style="text-indent: 2em;">5. 因本协议产生任何争议，双方应友好协商解决；协商不成，任何一方有权向被告住所地有管辖权的人民法院提起诉。</div>
      </div>
    </b-modal>
    <div style="padding: 1.2rem 1.2rem 0 1.2rem;">
      <div style="height: 2rem;">
        <div class="close" @click="close">
          <fa icon="times"/>
        </div>
      </div>
    </div>
    <div class="logo">
      <img src="https://img.xinshu.me/upload/3acd2ee9a4954dccbded9c0a61cd4146" alt="">
    </div>
    <template v-if="status === 'noAst'">
      <div>宝宝树小助手正忙，请稍后再来</div>
    </template>
    <template v-else-if="status === 'loadAst'">
      <loading :text="'正在获取宝宝树小助手...'"/>
    </template>
    <template v-else-if="status === 'noPhone'">
      <div style="height: 2rem; line-height: 2rem;">您输入的手机号没有注册宝宝树</div>
      <div style="height: 2rem; line-height: 2rem;">请先前往宝宝树注册或<span
        style="color: #E63626; cursor: pointer;" @click="init">重新输入</span></div>
    </template>
    <template v-else-if="status === 'sendFrequently'">
      <div style="height: 2rem; line-height: 2rem;">该手机号的验证码发送频繁</div>
      <div style="height: 2rem; line-height: 2rem;">请稍等片刻或<span
        style="color: #E63626; cursor: pointer;" @click="init">重新输入</span></div>
    </template>
    <template v-else-if="status === 'expired'">
      <div>宝宝树小助手已过期，<span style="color: #E63626; cursor: pointer;" @click="init">刷新重试</span></div>
    </template>
    <template v-else-if="status === 'uploadData'">
      <div style="height: 2rem; line-height: 2rem;">您的宝宝树书籍正在制作中，请稍后再来</div>
      <div style="height: 2rem; line-height: 2rem; color: #E63626; cursor: pointer;"
           @click="goShelf">前往书架
      </div>
    </template>
    <template v-else-if="status === 'chooseHome'">
      <div style="padding: 0 1.2rem;">
        <template v-if="homeList.length === 0">
          <div style="height: 2rem; line-height: 2rem;">您还没有宝宝树小家</div>
          <div style="height: 2rem; line-height: 2rem;">请先前往宝宝树添加后再来做书</div>
        </template>
        <template v-else>
          <div class="title">请选择小家开始做书
            <div>可同时选择多个小家，每个小家对应一本书</div>
          </div>
          <div class="baby-list">
            <b-card
              :class="{'baby-card': selected.indexOf(item) > -1}"
              v-for="item in homeList"
              :key="item.bid"
              @click="toggleSelect(item)"
            >
              <span class="check" v-if="selected.indexOf(item) > -1">
                <fa icon="check"/>
              </span>
              <div class="baby-content">
                <div class="avatar">
                  <avatar :src="item.avatar" round
                          fallback="https://img.xinshu.me/upload/32ee2d3e48eb4c2c8f8df34efe00adac"/>
                </div>
                <div class="nickname">{{item.nickname}}</div>
              </div>
            </b-card>
          </div>
        </template>
      </div>
      <div class="comfirm-choose">
        <div class="choose-btn" :class="{'disabled-btn': selected.length === 0 || doing}"
             @click="chooseHome">
          {{doing ? '正在做书，请稍等...' : '已选 ' + selected.length + ' 个，开始做书'}}
        </div>
      </div>
    </template>
    <template v-else>
      <div style="padding: 0 1.2rem;">
        <div class="phone-line">
          <div class="ti">手机号</div>
          <div class="phone">
            <input type="tel" placeholder="请输入手机号" v-model="phone">
          </div>
          <div class="code-btn" :class="{'no-click': !canSendCode || !legalPhone}"
               @click="submitPhone">{{canSendCode ? '获取验证码' : codeCount + 's'}}
          </div>
        </div>
        <hr>
        <div class="phone-line">
          <div class="ti">验证码</div>
          <div class="code">
            <input type="text" placeholder="请输入验证码" v-model="code">
          </div>
        </div>
        <hr>
        <template v-if="canInviteCode">
          <template v-if="showInviteCode">
            <div class="phone-line">
              <div class="ti">邀请码</div>
              <div class="code">
                <input type="text" maxlength="6" placeholder="请输入邀请码" v-model="inviteCode">
              </div>
            </div>
            <hr>
          </template>
          <template v-else>
            <div class="invite-text" @click="inviteBtn">填写邀请码</div>
          </template>
        </template>
        <div class="protocol" v-b-modal="'bbs-protocol'"><span style="color: #ccc;">登录即代表同意</span>《用户授权协议》
        </div>
        <div class="confirm-btn"
             :class="{'disabled-btn': !canClickSign}"
             @click="submitCode"
        >{{(status === 'checkCode' || status === 'codeRight' || status ===
          'chooseHome') ? '正在登录...' : '授权并登录宝宝树'}}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'bbsAssistant',
  title: '宝宝树小助手',
  data() {
    return {
      status: '', // expired, loadAst, noAst, noPhone, sendFrequently, inputPhone, inputCode, checkCode, codeError, codeError3 codeRight, chooseHome, uploadData
      ast: '',
      interval: null,
      phone: '',
      code: '',
      codeInterval: null,
      codeCount: 0,
      homeList: [],
      doing: false,
      selected: [],
      showCodeErr: true,
      showCodeErr3: true,
      assignData: true,
      assignPhone: true,
      canInviteCode: false,
      showInviteCode: false,
      inviteCode: ''
    }
  },
  watch: {
    phone(val) {
      this.code = ''
      clearInterval(this.codeInterval)
      this.codeInterval = null
      this.codeCount = 0
    }
  },
  computed: {
    canSendCode() {
      return this.codeCount === 0
    },
    canClickSign() {
      const reg = /^\d{6}$/
      return this.legalPhone && reg.test(this.code) && this.status === 'inputCode'
    },
    legalPhone() {
      return /^1[3456789]\d{9}$/.test(this.phone)
    }
  },
  beforeDestroy() {
    this.closePolling()
  },
  async mounted() {
    this.init()
  },
  methods: {
    judgeInviteCode() {
      return this.$req.get('api/book/bbs/invite_code_switch').then((res) => {
        this.canInviteCode = res.enable
      }).catch(() => null)
    },
    inviteBtn() {
      this.showInviteCode = !this.showInviteCode
    },
    goShelf() {
      this.$router.push('/books?bookType=bbsbook')
    },
    toggleSelect(item) {
      if (this.selected.indexOf(item) === -1) {
        this.selected = this.selected.concat(item)
      } else {
        this.selected = this.selected.filter(i => i !== item)
      }
    },
    startCodeCount() {
      this.codeInterval = setInterval(() => {
        this.codeCount--
        if (this.codeCount === 0) {
          clearInterval(this.codeInterval)
        }
      }, 1000)
    },
    async init() {
      clearInterval(this.codeInterval)
      this.codeInterval = null
      this.codeCount = 0
      this.selected = []
      this.phone = ''
      this.code = ''
      this.inviteCode = ''
      this.showInviteCode = false
      this.canInviteCode = false
      this.showCodeErr = true
      this.showCodeErr3 = true
      this.assignData = true
      this.assignPhone = true
      this.status = 'loadAst'
      await this.judgeInviteCode()
      this.ast = await this.getAst()
      if (this.ast.xinshuUserId) {
        this.pollingAst()
      } else {
        this.status = 'noAst'
      }
    },
    getAst() {
      return this.$req.get('/api/book/bbs/assistants')
    },
    getAstStatus() {
      return this.$req.get('/api/book/bbs/assistants/status')
    },
    closePolling() {
      clearInterval(this.interval)
      this.interval = null
    },
    pollingAst() {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        return this.$req.get('/api/book/bbs/assistants/status').then(res => {
          this.ast = res
          if (this.ast.status === 'input_phone') {
            this.status = 'inputPhone'
          } else if (this.ast.status === 'sending_auth_code' || this.ast.status ===
            'input_auth_code') {
            if (this.assignPhone && this.phone === '') {
              this.phone = this.ast.phone
              this.assignPhone = false
            }
            this.status = 'inputCode'
          } else if (this.ast.status === 'phone_not_registered') {
            this.status = 'noPhone'
          } else if (this.ast.status === 'sending_auth_code_frequently') {
            this.status = 'sendFrequently'
          } else if (this.ast.status === 'check_auth_code') {
            this.status = 'checkCode'
          } else if (this.ast.status === 'check_auth_code_success') {
            this.status = 'codeRight'
          } else if (this.ast.status === 'check_auth_code_fail' || this.ast.status ===
            'check_auth_code_fail_2') {
            if (this.showCodeErr) {
              this.$alert.warn('验证码错误，请重新输入')
              this.showCodeErr = false
              this.code = ''
            }
            this.status = 'inputCode'
          } else if (this.ast.status === 'check_auth_code_fail_3') {
            if (this.showCodeErr3) {
              this.$alert.warn('验证码错误3次，请重新获取验证码')
              this.showCodeErr3 = false
              clearInterval(this.codeInterval)
              this.codeInterval = null
              this.codeCount = 0
              if (this.assignPhone && this.phone === '') {
                this.phone = this.ast.phone
                this.assignPhone = false
              }
              this.code = ''
            }
            this.status = 'inputCode'
          } else if (this.ast.status === 'home_list_empty') {
            if (this.assignData) {
              this.homeList = []
              this.assignData = false
            }
            this.status = 'chooseHome'
          } else if (this.ast.status === 'choose_home' && this.ast.homeList) {
            if (this.assignData) {
              this.homeList = this.ast.homeList
              this.assignData = false
            }
            this.status = 'chooseHome'
          } else if (this.ast.status === 'uploading_data') {
            this.status = 'uploadData'
            this.closePolling()
          }
        }).catch((err) => {
          this.status = 'expired'
          this.closePolling()
          console.log(err)
        })
      }, 2000)
    },
    submitPhone() {
      if (!this.legalPhone) {
        this.$alert.warn('请填写正确的手机号')
        return
      }
      return this.$req.post('/api/book/bbs/upload_phone', {
        assistant_id: this.ast.id,
        phone: this.phone
      }).then((res) => {
        if (res.status === 'sending_auth_code') {
          this.assignPhone = false
          this.codeCount = 60
          this.startCodeCount()
          this.code = ''
        }
      }).catch(() => {
        this.$alert.warn('请填写正确的手机号')
      })
    },
    submitCode() {
      if (!this.canClickSign) {
        return
      }
      this.showCodeErr = true
      this.showCodeErr3 = true
      return this.$req.post('/api/book/bbs/upload_auth_code', {
        assistant_id: this.ast.id,
        auth_code: this.code,
        invitation_code: this.inviteCode
      })
    },
    chooseHome() {
      if (this.doing || this.selected.length === 0) {
        return
      }
      const ids = this.selected.map(val => {
        return val.hid
      })
      this.doing = true
      return this.$req.post('/api/book/bbs/choose_home', {
        hids: ids
      }).then(async (res) => {
        this.doing = false
        if (res[0].id) {
          await this.$dialog.confirm({
            title: '宝宝树小助手',
            content: '正在持续导出，请稍后返回书架查看',
            okTitle: '好的',
            okOnly: true
          })
          const bookId = this.getBookId(res[0])
          this.$router.push(`/books/${bookId}`)
        }
      }).catch((err) => {
        console.log(err)
        this.$alert.error('导入失败，请重新尝试')
        this.doing = false
      })
    },
    close() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
[data-page="qbbAssistant"] {
  background-image: none;
  background-color: #fff;

  main {
    padding-bottom: 0 !important;
  }
}
</style>
<style lang="scss" scoped>
$bbscolor: #E63626;

.bbs-assistant {
  max-width: 560px;
  text-align: center;
  min-height: 100vh;
  padding: 0;
  position: relative;

  .close {
    float: left;
    font-size: 2rem;
    cursor: pointer;
  }

  .countdown {
    height: 2rem;
    float: right;
    line-height: 2rem;
    font-size: 1.2rem;
  }

  .logo {
    padding: 2rem 0 3rem 0;

    img {
      height: 5rem;
    }
  }

  .invite-text {
    text-align: right;
    padding: 0 0.2rem;
    color: $bbscolor;
    cursor: pointer;
  }

  .phone-line {
    display: flex;
    height: 2.4rem;
    padding: 0 2px;

    .ti {
      flex: 1;
      line-height: 2.4rem;
      text-align: left;
    }

    .phone {
      flex: 2;
      padding: 0 0.5rem;

      input {
        height: 100%;
        border: none;
        padding: 0;
        line-height: 2.4rem;
        width: 100%;
      }
    }

    .code-btn {
      flex: 1;
      line-height: 2.4rem;
      border: 1px solid #343a40;
      border-radius: 0.4rem;
      cursor: pointer;
    }

    .no-click {
      color: #ccc;
      border: 1px solid #ccc;
    }

    .code {
      flex: 3;
      padding: 0 0.5rem;

      input {
        height: 100%;
        border: none;
        padding: 0;
        line-height: 2.4rem;
        width: 100%;
      }
    }
  }

  .protocol {
    text-align: left;
    margin: 1.6rem 0;
    font-size: 0.8rem;
    cursor: pointer;
  }

  .confirm-btn {
    margin-top: 2rem;
    background-color: $bbscolor;
    border-radius: 50rem;
    height: 3rem;
    line-height: 3rem;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .disabled-btn {
    background-color: #ccc !important;
  }

  .title {
    font-weight: bold;
    padding: 0.6rem 0;
    text-align: left;

    div {
      color: #999;
      font-weight: normal;
    }
  }

  .baby-list {
    .baby-card {
      border: 1px solid $bbscolor;
      border-radius: 8px;
    }

    .check {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      font-size: 12px;
      line-height: 1;
      padding: 1px;
      padding-left: 12px;
      padding-bottom: 12px;
      background-color: $bbscolor;
      background: linear-gradient(45deg, transparent 18px, $bbscolor 0);
      transform-origin: top right;
      border-radius: 0 4px 0 0;
      transform: scale(1.25);
    }

    .baby-content {
      display: flex;
      align-items: center;

      .avatar {
        flex: 1;
      }

      .nickname {
        flex: 4;
        text-align: left;
        padding: 0 2rem;
        font-weight: bold;
      }
    }
  }

  .comfirm-choose {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 560px;
    height: 5rem;
    padding: 1rem;
    background-color: #fff;

    .choose-btn {
      color: #fff;
      background-color: $bbscolor;
      border-radius: 50rem;
      height: 3rem;
      line-height: 3rem;
    }
  }
}
</style>
