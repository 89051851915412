<template>
  <div class="assistant">
    <div class="warn" v-if="warning">
      <b-card>
        <square class="mx-auto" src="https://static.weixinshu.com/assets/images/deer/cry.png"
                style="width: 240px;"/>
        <h2>暂时无法提供服务哦</h2>
        <p>
          因特殊原因，小助手暂时无法提供服务，将于 <b>1月20日</b> 恢复使用。
        </p>
        <p>
          <qiyu variant="link">咨询客服</qiyu>
        </p>
      </b-card>
    </div>

    <div class="warn" v-else-if="isAgentUser && !dedicated">
      <b-card>
        <p>
          <emotion value="cry" size="240px"/>
        </p>
        <h5>暂不支持自自助使用小助手哦</h5>
        <p>请联系您的请联系客服或做书小编获取专属链接</p>

        <b-btn block @click="$router.back()">返回</b-btn>
      </b-card>
    </div>

    <div class="warn" v-else-if="isMobile && !dedicated">
      <p>
        <img src="../assets/images/assistant-help.png">
      </p>
      <h5>小助手暂不支持在手机端使用</h5>
      <div>请在电脑上访问下面的网址</div>
      <div class="text-primary mt-n2" style="font-size: 3em;">weixinshu.com</div>
      <div>（微信书拼音）</div>
    </div>

    <template v-else-if="!hasLogin && !dedicated">
      <div class="info text-center">
        <b-card title="微信书小助手">
          <img src="https://static.weixinshu.com/assets/images/deer/wink.png"
               style="max-width: 240px;"/>

          <div>
            为好友做书
            <br>
            导出分组与私密的内容
          </div>
          <div class="btn-area">
            <b-btn to="/signin?redirect=/assistant" variant="primary" block>登录后开始使用</b-btn>
          </div>
        </b-card>
      </div>
    </template>

    <div :class="['step-' + step]" v-else>
      <b-row style="flex: 1;">
        <b-col class="mb-3">
          <b-card class="centered" v-if="error">
            <square src="https://static.weixinshu.com/assets/images/deer/cry.png"
                    style="width: 240px;"/>
            <template v-if="error.message === 'keyInvalid'">
              <h2>对不起，链接已失效</h2>
              <p>请联系客服获取最新链接后再回来</p>
            </template>
            <template v-if="error.message === 'notLogin'">
              <h2>对不起，您还没有登录微信书哦</h2>
              <p>请点击下方链接登录</p>
              <div>
                <b-btn variant="primary" block to="/signin?redirect=/assistant">登录</b-btn>
              </div>
            </template>
            <template v-else-if="error.message === 'noWorkers'">
              <h2>没有相关的小助手哦</h2>
              <p>请联系客服获取最新链接后再回来</p>
            </template>
            <template v-else-if="error.message === 'exceeded'">
              <h2>作品数量已超过限制</h2>
              <p>请联系客服获取解决办法</p>
            </template>
            <template v-else>
              <h1>发生了一些错误哦</h1>
              <p>
                {{error.message}}
                <a href="javascript:" @click="showError = !showError">
                  {{showError ? '收起' : '展开'}}错误详情
                </a>
              </p>
              <div class="error" v-if="showError">{{error.response}}</div>
            </template>
          </b-card>
          <b-card no-body v-else-if="loading">
            <b-row class="h-100">
              <b-col class="sidebar" sm="4">
                <div class="worker">
                  <div class="mx-auto" style="width: 5em">
                    <ske type="square"/>
                  </div>
                  <div>
                    <ske width="8em"></ske>
                  </div>
                  <ske width="7em"></ske>
                </div>
                <ul class="worker-list">
                  <li v-for="i in 6" :key="i">
                    <b-row align-v="center">
                      <b-col cols="auto" class="pr-0">
                        <ske type="square" style="width: 3rem;"/>
                      </b-col>
                      <b-col style="min-width: 0;">
                        <div class="title">
                          <ske width="8em"/>
                        </div>
                        <ske width="6em"/>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
              <b-col class="text-center">
                <b-card-body>
                  <div class="py-5">
                    <div class="mb-3">
                      <fa icon="clock" size="4x"/>
                    </div>
                    <h2>正在获取小助手状态</h2>
                    <div class="mt-3">
                      <ske width="15em"/>
                    </div>
                    <div>
                      <ske width="16em"/>
                    </div>

                    <div class="mx-auto mt-3" style="width: 200px">
                      <ske type="square"/>
                    </div>

                    <div class="mt-3">请稍候...</div>
                  </div>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-body v-else-if="step <= 1">
            <b-row class="flex-grow-1" style="height: 100%;">
              <b-col class="sidebar" sm="4" v-if="!dedicated">
                <div class="text-center worker">
                  <avatar :src="user.avatar"/>
                  <div class="title mt-2" v-if="worker">
                    您正在使用
                    <br>
                    #{{worker.idfv.replace(/[^\d]/g, '').slice(0, 3)}}
                  </div>
                  <div class="mt-2" v-else>
                    {{user.nickname}}
                  </div>
                </div>
                <ul class="worker-list">
                  <li v-if="publicWorkers.length">
                    <fa icon="weixin" fw/>
                    其他小助手状态 ({{publicWorkers.length}})
                  </li>
                  <li :class="{active: worker && worker.idfv === item.idfv}" :key="index"
                      :title="item.idfv"
                      :data-openid="item.openid" v-for="(item, index) in publicWorkers">
                    <b-row align-v="center">
                      <b-col cols="auto" class="pr-0">
                        <avatar size="3em" :src="item.avatar"
                                fallback="https://static.weixinshu.com/assets/images/deer/wink.png"/>
                      </b-col>
                      <b-col style="min-width: 0;">
                        <div class="title">
                          <template v-if="item.nickname">{{item.nickname}}</template>
                          <template v-else>
                            #{{item.idfv.replace(/[^\d]/g, '').slice(0, 3)}}
                          </template>
                        </div>
                        <div>
                          <span class="text-danger" v-if="!item.online">[已下线]</span>
                          <b v-else-if="item.status !== 'unLogin'">[{{getStatus(item.status)}}]</b>
                          <b v-else-if="item.lock">[使用中]</b>
                          <b v-else-if="item.openid">[制作中]</b>
                          <span v-else>[空闲]</span>
                        </div>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
              <b-col class="text-center">
                <b-card-body>
                  <template v-if="worker">
                    <h2 class="card-title">
                      <i class="fab fa-weixin text-success"></i>
                      扫码登录微信书小助手
                    </h2>
                    <div v-if="userAvatar">
                      限本账号
                      <avatar :src="userAvatar" size="2em" alt/>
                      {{user.nickname}}
                      绑定的微信
                    </div>
                    <b class="text-danger">
                      需摄像头扫码，不支持长按识别或相册识别
                      <br>
                    </b>
                    <template v-if="worker.status === 'unLogin'">
                      <div class="qrcode" style="max-width: 240px;">
                        <square :src="qrcode" alt=""/>
                      </div>
                      <div class="mb-3" v-if="expires">请在{{Math.floor(expires / 1000)}}秒内扫码</div>
                      <div class="alert alert-warning">
                        <fa icon="exclamation-circle"/>
                        部分用户可能会因为异地登录被限制扫码，请您谨慎操作
                      </div>
                    </template>
                    <div class="text-center" v-else>
                      <div class="my-3">
                        <avatar size="200px" :src="worker.avatar"/>
                      </div>
                      <div v-if="worker.weixinId">{{worker.nickname || '匿名用户'}}</div>
                      <p>正在使用当前小助手...</p>
                    </div>
                  </template>
                  <template v-else-if="noAvailable">
                    <h2 class="card-title">暂无可用小助手哦</h2>
                    <div class="mt-5">
                      <p>您可以在此等待分配或稍后回来</p>
                      <img src="https://static.weixinshu.com/assets/images/deer/wink.png"
                           class="emotion"/>
                    </div>
                  </template>
                  <template v-else-if="isTimeout">
                    <h2 class="card-title">由于长时间未扫码，已退出小助手</h2>
                    <img src="https://static.weixinshu.com/assets/images/deer/cry.png"
                         class="emotion"/>
                    <p>点击下方按钮重新选择</p>
                    <b-btn block @click="autoSelect">重新选择小助手</b-btn>
                  </template>
                  <template v-else>
                    <h2 class="card-title">已有可用小助手</h2>
                    <img src="https://static.weixinshu.com/assets/images/deer/wink.png"
                         class="emotion"/>
                    <p>点击下方按钮开始使用</p>
                    <b-btn block @click="autoSelect">为我分配小助手</b-btn>
                  </template>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-body :class="{disabled: step === 3 || step === 5}" v-else-if="step >= 2">
            <b-card-body style="overflow-x: hidden;overflow-y: auto;">
              <h2 class="card-title">开始做书</h2>
              <h3 class="sub-title">给自己做书</h3>
              <b-row align-v="center">
                <b-col cols="auto">
                  <avatar :src="worker.avatar"/>
                </b-col>
                <b-col cols="7">
                  <h5 style="margin-top: 0;">{{worker.nickname}}</h5>
                  <div>{{worker.weixinId}}</div>
                </b-col>
                <b-col class="text-right">
                  <b-btn variant="primary" block @click="makeBook(worker.weixinId)" class="my-3">
                    <fa icon="book"/>
                    导出私密及分组内容
                  </b-btn>
                </b-col>
              </b-row>

              <hr>

              <h3 class="sub-title">给朋友做书</h3>
              <b-form-group>
                <b-input size="lg" :disabled="!contactsNumber"
                         placeholder="输入好友昵称、备注或微信 ID 搜索"
                         type="search" v-model="searchKey"/>
              </b-form-group>

              <div v-if="contactsNumber === 0">
                小助手就绪中...
                稍后就可以为好友做书啦
              </div>
              <div v-else-if="contactsNumber < 0">
                <fa icon="sync" spin/>
                正在为您更新通讯录...
              </div>

              <div class="contacts-container" v-if="contacts">
                <table class="contacts" v-if="contacts.length">
                  <tbody>
                  <tr :key="item.wxid" v-for="item in contacts">
                    <td>
                      <avatar :src="item.imgUrl" :alt="item.nickname"/>
                    </td>
                    <td>
                      <h5 style="margin-top: 0;">
                        {{item.nickName}}
                        <span v-if="item.remark">({{item.remark}})</span>
                      </h5>
                      <div v-if="item.alias">{{item.alias}}</div>
                    </td>
                    <td>
                      <b-btn variant="outline-primary" @click="makeBook(item.wxid)">
                        给好友做书
                      </b-btn>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="text-center text-muted" v-else>
                  <img src="https://static.weixinshu.com/assets/images/deer/cry.png"
                       style="max-width: 200px;"
                       alt="Oooops">
                  <div>没有搜索到相关的好友哦</div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm xl="3" lg="4" v-if="!isMobile">
          <b-card no-body class="card text-center">
            <div class="card-body overflow-auto" v-if="step <= 1">
              <template v-if="!dedicated">
                <h2 class="card-title">视频使用教程</h2>
                <p>
                  <square v-b-modal="'guide'"
                          src="https://img.xinshu.me/upload/0d1c4ff050314869932821a3a5bc8992"
                          height="75%"/>

                </p>

                <b-modal id="guide" size="lg" title="小助手使用教程" hide-footer>
                  <video-player
                    src="https://img.xinshu.me/upload/f3390b753ea54bad9d4e38bfc511456a"/>
                </b-modal>
              </template>
              <h2 class="card-title">图文使用教程</h2>
              <img draggable="false"
                   src="https://img.xinshu.me/upload/f2c90917b87e3b9fee7371f85957066e"
                   alt="使用教程" style="border-radius: 4px;">
            </div>

            <div class="card-body" v-if="step === 2">
              <h2 class="card-title">您已登录</h2>
              <template v-if="contactsNumber">
                <p class="jumbotron">
                  请从左侧 <b class="text-danger">搜索好友</b>
                  或 <b class="text-danger">导出私密及分组内容</b>
                </p>
                <div class="jumbotron">
                  <p class="text-warning">
                    <i class="fa fa-info-circle" style="display: block; font-size: 4rem;"></i>
                  </p>
                  如您在5分钟以内仍未开始做书 <br> 小助手将会自动退出
                </div>
              </template>
              <div v-else>
                <p>
                  <img class="emotion"
                       src="https://static.weixinshu.com/assets/images/deer/wink.png">
                </p>
                <div>小助手正在就绪，请稍候...</div>
              </div>
            </div>

            <div class="card-body" v-if="step === 3">
              <h2 class="card-title">正在处理</h2>
              <p>
                <img class="emotion"
                     src="https://static.weixinshu.com/assets/images/deer/study.png">
              </p>
              <h5>小助手排版中...</h5>
              <p>根据朋友圈内容大约需要 1 ~ 5 分钟哦</p>
            </div>

            <div class="card-body" v-if="step === 4">
              <h2 class="card-title">排版完成</h2>
              <p>欧耶，内容已导出！</p>
              <div>作品更新可能会有延迟，请您稍后查看</div>
              <p>
                <img class="emotion"
                     src="https://static.weixinshu.com/assets/images/deer/happy.jpg">
              </p>
              <div class="btn-area" v-if="worker.category === 0">
                <b-btn variant="primary" block to="/books">进入书架</b-btn>
                <div class="mt-3 small text-muted">如果数据未更新，请等待公众号消息推送</div>
              </div>
            </div>

            <b-card-body v-if="step === 5">
              <h2 class="card-title">正在退出小助手</h2>
              <img class="emotion" src="https://static.weixinshu.com/assets/images/deer/wink.jpg">
              <p>感谢您的使用</p>
            </b-card-body>

            <div class="artifacts card-body" v-if="step >= 2 && artifacts && artifacts.length">
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th>以下是您最近排版完成的作品</th>
                </tr>
                </thead>
                <tbody>
                <tr :key="item.objectId" v-for="item of artifacts">
                  <td>
                    <h5 class="book-title">
                      <b-link :href="item.url" target="_blank">
                        <fa icon="book"/>
                        {{item.nickname}}的微信书
                      </b-link>
                    </h5>
                    <div class="small text-muted">
                      完成于 {{item.datetime}}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <b-card-footer v-if="step === 2 || step === 4">
              <b-btn block @click="logout">退出小助手</b-btn>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { findKey, sample } from 'lodash'

export default {
  name: 'assistant',
  components: {
    VideoPlayer: require('@/components/VideoPlayer').default
  },
  title: '微信书小助手',
  data() {
    return {
      warning: new Date() < new Date('2021-01-20'),
      idfv: '',
      dedicated: !!this.$route.query.idfv,
      loading: true,
      waitTime: 0,
      expires: 0,
      socket: null,
      host: 'https://pc.weixinshu.com',
      // host: 'http://localhost:3048',

      contacts: null,
      workers: [],
      artifacts: [],

      searchKey: '',

      showError: false,
      showConfirm: false,
      isTimeout: false,
      hasBook: false,
      stopped: false,
      lockForProp: false,
      error: null
    }
  },
  watch: {
    searchKey(val) {
      if (!val) {
        this.contacts = null
        return
      }
      this.searchContacts(val)
    },
    status(val) {
      if (val === 'waitMakeBook' || val === 'signin') {
        this.onLogin()
      }
      if (val === 'makeBookOver') {
        this.onFinish()
      }
    }
  },
  async created() {
    if (this.warning) {
      return
    }

    if (!this.hasLogin && !this.dedicated) {
      return
    }

    await this.connect()
    this.initialize()

    this.onbeforeunload = () => {
      this.$req.delete('/matrix/locks/' + this.uid, {baseURL: this.host})
    }
    window.addEventListener('beforeunload', this.onbeforeunload)
  },
  computed: {
    worker() {
      return this.workers.find(i => i.idfv === this.idfv)
    },
    userAvatar() {
      if (!this.user) {
        return ''
      }
      const src = this.user.headimgurl || this.user.avatar
      if (!src) {
        return ''
      }
      return src
    },
    userWeixinId() {
      if (!this.user) {
        return ''
      }
      return this.user.weixinId || this.user.wxid || ''
    },
    uid() {
      return this.user?.unionid
    },
    qrcode() {
      const worker = this.worker
      if (worker) {
        if (/^http/.test(worker.qrcode)) {
          return 'https://canvas.xinshu.me/qrcode?text=' + encodeURIComponent(worker.qrcode)
        } else {
          return worker.qrcode + '?ver=' + worker.qrcodeHash
        }
      }
      return ''
    },
    noAvailable() {
      return this.availableWorkers.length === 0
    },
    contactsNumber() {
      return this.worker?.contactNumber || 0
    },
    publicWorkers() {
      if (this.dedicated) {
        return []
      }
      return this.workers
        .filter(i => i.category === 0 && i.idfv !== this.idfv)
        .map(item => {
          item.avatar = item.avatar || item.lock?.avatar
          return item
        })
    },
    availableWorkers() {
      return this.workers.filter(i => i.status === 'unLogin' && i.online && i.category === 0)
    },
    isMe() {
      if (this.dedicated) {
        return true
      }
      if (this.uid && this.worker?.uid === this.uid) {
        return true
      }
      return this.userWeixinId && this.worker?.weixinId === this.userWeixinId.split('@')[0]
    },
    status() {
      return this.worker ? this.worker.status : 'unLogin'
    },
    step() {
      // step = 0 需扫码登录
      // step = 1 确认登录状态
      // step = 2 已登录，等待做书
      // step = 3 正在做书
      // step = 4 做书完成
      // step = 5 正在退出
      const statusMap = {
        0: ['unLogin', 'onClean'],
        1: ['signin'],
        2: ['initialize', 'waitMakeBook'],
        3: ['makeBook'],
        4: ['makeBookOver'],
        5: ['exit', 'timeoutExit']
      }

      if (!this.worker) {
        return 0
      }

      if (this.worker.for && this.isMe) {
        // 如果指定了做书的人且是当前用户
        // 则认为在 makeBook 步骤
        return 3
      }

      let step = findKey(statusMap, statusList => statusList.indexOf(this.status) > -1)
      step = parseInt(step)

      if (isNaN(step)) {
        return 0
      }

      if (step > 1 && !this.isMe) {
        // 登陆后检查是不是本人
        // 就重新获取小助手
        step = 1
      }

      return step
    }
  },
  methods: {
    getStatus(val) {
      return {
        unLogin: '空闲',
        signin: '登录中',
        initialize: '初始化中',
        waitMakeBook: '等待中',
        makeBook: '制作中',
        makeBookOver: '制作完成',
        timeoutExit: '已超时',
        exit: '已退出',
        onClean: '清理中'
      }[val]
    },
    async connect() {
      const io = await this.loadIo()
      const socket = io(this.host)
      socket.on('worker', response => {
        const idfv = response.idfv
        if (idfv) {
          const worker = this.workers.find(i => i.idfv === response.idfv)
          if (!worker) {
            this.workers.unshift(response)
          } else {
            this.workers.splice(this.workers.indexOf(worker), 1, response)
          }
        }
      })
      socket.on('timeout', (idfv) => {
        if (idfv === this.idfv) {
          this.onTimeout()
        }
      })
      socket.on('warning', ({idfv, message}) => {
        if (idfv === this.idfv && /系统检测到你的微信帐号有被盗嫌疑/.test(message)) {
          this.worker.openid = ''
          this.worker.lock = null
          this.worker.status = 'unLogin'
          this.idfv = ''
          this.$dialog.confirm({content: message, okOnly: true})
        }
      })
      this.socket = socket
      return new Promise(resolve => {
        socket.on('connect', () => {
          console.log('Connected')
          resolve()
        })
      })
    },
    loadIo() {
      if (window.io) {
        return Promise.resolve(window.io)
      }
      return new Promise(resolve => {
        const script = document.createElement('script')
        script.src = this.host + '/socket.io/socket.io.min.js'
        script.onload = () => {
          resolve(window.io)
        }
        document.body.appendChild(script)
      })
    },
    async initialize() {
      try {
        const workers = await this.fetchWorkers()
        this.loading = false
        this.workers = workers
        this.autoSelect()
      } catch (err) {
        this.loading = false
        this.error = err
      }
    },
    autoSelect() {
      if (this.worker) {
        return
      }
      const worker = this.workers.find(i => i.lock?.uid === this.uid || i.uid === this.uid)
      if (worker) {
        this.idfv = worker.idfv
        return
      }
      if (this.$route.query.idfv) {
        this.selectWorker(this.$route.query.idfv)
        return
      }
      if (!this.availableWorkers.length) {
        return
      }
      this.selectWorker(sample(this.availableWorkers)?.idfv)
    },
    async selectWorker(idfv) {
      if (!idfv) {
        return
      }
      this.idfv = idfv
      if (!this.dedicated) {
        const {expires} = await this.$req.post('/matrix/locks/' + this.uid, {
          idfv,
          user: this.user
        }, {baseURL: this.host})
        this.setTimer(expires)
      }
    },

    fetchWorkers() {
      return this.$req.get('/matrix', {
        baseURL: this.host,
        params: {
          openid: this.$route.query.idfv ? undefined : this.uid
        }
      })
    },
    async fetchArtifacts() {
      const weixinId = this.worker.weixinId
      if (!weixinId) {
        return
      }
      const artifacts = await this.$req.get('/matrix/users/' + weixinId + '/artifacts', {
        baseURL: this.host
      })
      this.artifacts = artifacts.map(item => {
        const date = new Date(item.createdAt)
        item.datetime = this.$day(date).format('YYYY/MM/DD HH:mm')
        return item
      })
    },
    async searchContacts(searchKey) {
      this.contacts = await this.$req.get('/matrix/users/' + this.worker.weixinId, {
        baseURL: this.host,
        params: {
          filter: searchKey
        }
      })
    },

    setTimer(expires) {
      this.removeTimer()
      this.isTimeout = false
      this.expires = expires
      this.interval = setInterval(() => {
        this.expires -= 1000
        if (this.expires <= 0) {
          this.onTimeout()
          this.removeTimer()
        }
      }, 1000)
    },
    removeTimer() {
      clearInterval(this.interval)
    },
    onTimeout() {
      if (this.step <= 1) {
        if (this.worker) {
          this.worker.lock = null
        }
        this.idfv = ''
        this.isTimeout = true
      }
    },

    makeBook(weixinId) {
      this.worker.for = weixinId
      return this.saveWorker({for: weixinId})
    },
    saveWorker(data) {
      return this.$req.put('/matrix/' + this.worker.idfv, data, {baseURL: this.host})
    },
    async logout() {
      const confirmed = await this.$dialog.confirm('是否要退出小助手')
      if (!confirmed) {
        return
      }
      this.worker.status = 'exit'
      return this.saveWorker({status: 'exit'})
    },

    onLogin() {
      this.fetchArtifacts()
    },
    onFinish() {
      this.fetchArtifacts()
    }
  },
  beforeDestroy() {
    this.stopped = true
    this.socket && this.socket.disconnect()
    window.removeEventListener('beforeunload', this.onbeforeunload)
    if (this.uid) {
      this.$req.delete('/matrix/locks/' + this.uid, {baseURL: this.host})
    }
  }
}
</script>

<style>
body[data-page="assistant"] {
  background-color: #fb5c88;
  background-image: url(https://img.xinshu.me/upload/970c7b830c00455d831f3ff9c8262418),
  linear-gradient(to bottom left, #FE92BC, #FE7479);
  background-size: contain;
  background-position: center 80%;
  background-repeat: no-repeat;
}
</style>

<style scoped lang="scss">
.assistant {
  max-width: 1280px;

  .warn {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    text-align: center;
  }

  #assistant {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .info {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

.overflow-auto {
  overflow-x: hidden;
  overflow-y: auto;
}

.card {
  max-height: calc(100vh - #{8rem});
  height: calc(100vh - #{8rem});
}

.step-0, .step-1 {
  .card-body > * {
    position: relative;
    z-index: 2;
  }
}

.jumbotron {
  padding: 1rem;
  background-color: #eee;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.error {
  padding: 1rem;
  border-radius: 4px;
  background-color: #f2f2f2;
}

.emotion {
  max-width: 180px;
}

.login-avatar {
  width: 180px;
  height: 180px;
  border-radius: 4px;
  margin: 3em auto;
  display: block;
  border: 1px solid #eee;
}

.placeholder {
  position: relative;
  display: inline-block;

  span {
    display: block;
    width: 8em;
    height: 1.5em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
  }

  &:after {
    background-color: #f2f2f2;
    display: block;
    content: '';
    padding-bottom: 100%;
  }
}

.message {
  img {
    border: 1px solid $bg-gray;
    border-radius: 4px;
  }

  &-content {
    border-radius: 4px;
    padding: 1em;
    background-color: $bg-gray;
    position: relative;
    text-align: left;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 10px;
      left: -8px;
      border-style: solid;
      border-width: 8px;
      border-color: transparent;
      border-left: none;
      border-right-color: $bg-gray;
    }
  }

  margin-bottom: 1em;
}

.card-title {
  padding: 1rem 0;
  margin-bottom: 0;
}

.sidebar {
  height: 100%;
  max-width: 20em;
  padding-right: 0;
  width: 33%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.avatar-sm {
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  border-radius: 4px;
  border: 1px solid #eee;
}

.worker {
  text-align: center;
  padding: 1.5rem 1rem;
  background-color: #fff;

  .title {
    font-weight: bold;
  }
}

.worker-list {
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, .1);
  border-top-right-radius: 12px;
  border-right: 1px solid #eee;
  background-color: #f7f7f7;
  color: #666;
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;

  li {
    border-bottom: 1px solid #eee;
    overflow: hidden;
    padding: .75rem 1rem;
    user-select: none;

    .avatar {
      background-color: #fff;
    }

    .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: bold;
    }
  }
}

.card {
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  margin-bottom: 0;
  border-color: transparent;
  overflow: hidden;

  &.centered {
    text-align: center;

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      opacity: .8;
      user-select: none;
      pointer-events: none;
    }

    .step-3 &:before {
      content: '正在排版，请稍候...';
    }

    .step-5 &:before {
      content: '正在退出，暂时无法操作哦';
    }

    &:before {
      color: #333;
      position: absolute;
      line-height: 2;
      height: 2em;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      margin: auto;
      font-size: 1.5em;
      z-index: 5;
    }
  }
}

.btn-area {
  margin: 1em auto;
  max-width: 80%;

  .btn {
    display: block;
    width: 100%;
    line-height: 1.5;
  }
}

.qrcode {
  user-select: none;
  position: relative;
  z-index: 0;
  margin: 1.5rem auto;

  &.loading {
    pointer-events: none;

    img {
      user-select: none;
      opacity: .5;
    }
  }

  img, .placeholder {
    width: 240px;
    height: 240px;
    pointer-events: none;
  }
}

.card-title {
  font-size: 20px;
  text-align: center;
}

.sub-title {
  font-size: 16px;
  margin-bottom: 1em;

  &:before {
    background-color: $primary;
    width: .4em;
    float: left;
    content: '';
    height: 1.2em;
    vertical-align: baseline;
    margin-right: .5em;
    border-radius: 100px;
  }
}

.avatar {
  width: 5em;
  height: 5em;
  object-fit: cover;
  border-radius: 13%;
  border: 1px solid #eee;
}

.contacts {
  width: 100%;

  td {
    padding: .75em 0;

    &:first-of-type {
      width: 6em;
    }

    &:last-of-type {
      width: 8em;
    }
  }
}

.artifacts {
  text-align: left;
  background-color: rgba(0, 0, 0, .03);
  border-top: 1px solid rgba(0, 0, 0, .125);
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;

  .book-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
